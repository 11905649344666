import React from 'react';
import TestItem from '../Components/Test/TestItem';
// import Footer from '../Components/Footer/Footer';

function Test() {


  return (
    <main className='h-screen'>
      <TestItem />
      {/* <Footer/> */}
    </main>
  );
}

export default Test;
